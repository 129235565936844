import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

// Connects to data-controller="acceptance-rate-by-language"
export default class extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const graphType = controller.optionsValue.type

    let series
    let categories
    let yAxisMaxAndTickAmountSettings = {}

    if (graphType === 'spline') {
      series = controller.parsedGraphData.series
      categories = controller.parsedGraphData.categories
    } else if (graphType === 'column') {
      const columnData = controller.parsedGraphData.series
      series = [{ data: columnData }]
      const maxValue = Math.max(...columnData.map(o => o.y))
      yAxisMaxAndTickAmountSettings = this.getYAxisMaxAndTickAmountSettings(maxValue)
    }

    return {
      chart: {
        type: graphType,
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          step: (categories?.length || 0) > 8 ? 3 : 1,
          style: {
            color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          formatter: function () {
            return controller.formatValue(this.value)
          },
          style: {
            color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]
          }
        },
        tickInterval: 10,
        ...yAxisMaxAndTickAmountSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent',
          minPointLength: 5,
          colorByPoint: true,
          maxPointWidth: 150,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'justify',
            formatter: function () {
              return controller.formatValue(this.y > 0 ? this.y : null)
            },
            style: {
              color: controller.darkMode ? controller.tailwindColors.white : controller.tailwindColors.black
            }
          }
        },
        spline: {
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        },
        line: {
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          step: 'center'
        }
      },
      colors: this.breakdownColors,
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        outside: false,
        shared: graphType === 'spline',
        formatter: function () {
          if (graphType === 'spline') {
            const breakDownValues = this.points.map((p) => {
              return breakDownValue({
                name: p.series.name,
                value: `${p.y}%`,
                style: `background-color: ${p.series.color};`,
                type: 'line'
              })
            })
            return card({
              header: this.x,
              breakDownValues
            })
          } else {
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
              <div class="flex items-center gap-1">
                <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                <div class="font-medium text-gray-700 dark:text-gray-300">${this.point.name}</div>
              </div>
              <div class="flex flex-col gap-2 mt-3">
                <div class="flex">
                  <div class="font-medium text-black dark:text-white">Total suggestions</div>
                  <div class="ml-auto font-semibold text-black dark:text-white">${this.point.total_suggestions_count}</div>
                </div>
                <div class="flex">
                  <div class="font-medium text-black dark:text-white">Total acceptances</div>
                  <div class="ml-auto font-semibold text-black dark:text-white">${this.point.total_acceptances_count}</div>
                </div>
              </div>
            </div>
            `
          }
        }
      }
    }
  }

  formatValue (value) {
    return value != null ? `${value}%` : '--'
  }
}
